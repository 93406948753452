<template>
    <div class="kteventsection3_container">
        <p class="kteventsection3_text">
            KT닷컴 단독 혜택 및 <br />
            어플레이즈 추가 혜택을 놓치지 마세요.
        </p>
        <div class="kteventsection3_benefit_wrap dp_flex flex_justify_center">
            <span class="kteventsection3_benefit">
                KT닷컴 단독 혜택
            </span>
        </div>

        <ul class="dp_flex flex_column kteventsection3_benefit_list">
            <li class="kteventsection3_benefit_list_item">
                <span class="kteventsection3_benefit_itemlabel">혜택01</span>
                <div class="kteventsection3_benefit1_textwrap">
                    <h3 class="kteventsection3_benefit_title2">오직 KT닷컴에서만!</h3>
                    <h2 class="kteventsection3_benefit_title1">
                        Galaxy Z Flip6 <br />
                        마루는 강쥐 에디션
                    </h2>
                </div>
                <img
                    class="kteventsection3_benefit1_img"
                    src="/media/img/collaboration/kteventsection2_benefit1.png"
                    alt="마루는 강쥐 에디션"
                />
            </li>
            <li class="kteventsection3_benefit_list_item">
                <span class="kteventsection3_benefit_itemlabel">혜택02</span>
                <div class="kteventsection3_benefit2_textwrap">
                    <h2 class="kteventsection3_benefit_title1">
                        Galaxy Z Fold6 <br />
                        1TB 무료 업그레이드
                    </h2>
                    <h4 class="kteventsection3_benefit_desc">
                        1TB 구매 시 512GB 가격으로 드려요.
                    </h4>
                </div>
                <img
                    class="kteventsection3_benefit2_img"
                    src="/media/img/collaboration/kteventsection2_benefit2.png"
                    alt="1TB Fold"
                />
            </li>

            <li class="kteventsection3_benefit_list_item">
                <span class="kteventsection3_benefit_itemlabel">혜택03</span>
                <div class="kteventsection3_benefit3_textwrap">
                    <h2 class="kteventsection3_benefit_title1">
                        최대 164만원 할인
                    </h2>
                    <h4 class="kteventsection3_benefit_desc">
                        멤버십 포인트 할인(5만원) <br />
                        중고폰 보상(최대 120만원)<br />
                        BC/삼성카드 청구 할인 5%<br />
                        통신사 할인 (27만원)
                    </h4>
                </div>
                <img
                    class="kteventsection3_benefit3_img"
                    src="/media/img/collaboration/kteventsection2_benefit3.png"
                    alt="최대 45만원 추가할인"
                />
            </li>

            <li class="kteventsection3_benefit_list_item">
                <span class="kteventsection3_benefit_itemlabel">혜택04</span>
                <div class="kteventsection3_benefit4_textwrap">
                    <h2 class="kteventsection3_benefit_title1">
                        푸짐한 사은품
                    </h2>
                    <h4 class="kteventsection3_benefit_desc">
                        삼성 정품 케이스 기본 제공 <br />
                        로우로우 파우치<br />
                        갤럭시링/버즈3/워치 등
                    </h4>
                </div>
                <img
                    class="kteventsection3_benefit4_img"
                    src="/media/img/collaboration/kteventsection2_benefit4.png"
                    alt="삼성 정품 케이스 기본 제공 로우로우 파우치 갤럭시링/버즈3/워치 등"
                />
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'KtEventSection3',

  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style src="@/assets/css/collaboration/collaborfont.css"></style>
<style scoped src="@/assets/css/collaboration/kteventsection3.css"></style>
